import { ForwardedRef, forwardRef, MutableRefObject, useEffect, useState } from 'react'
import { Control, UseFormGetValues, useWatch } from 'react-hook-form'
import uuid from 'react-uuid'
import { GlobalStateProps } from '@providers/globalStore'
import { DNGeneralOptions, FormDNModel } from '@services/model/form/form.DN.model'
import { FormStatusEnum } from '@services/model/form/form.model'
import { UserInfo } from '@services/model/user.model'
import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import FormTypography from '@components/form/typography'
import FormMultipleSelect from '@components/form/multipleSelect'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormMediumMap from '@components/form/mediumMap'
import FormFreeText from '@components/form/freeText'
import { GetTeamOptions } from '@utils/teamOptions'
import { GetDistrictOptions } from '@utils/districtOptions'

const UUID = uuid()

function PartAInner(
  {
    control,
    getValues,
    formStatus,
    dnGeneralOptions,
    globalState,
    hasParentForm,
    userInfo,
    isMounted,
  }: {
    control: Control<FormDNModel>
    getValues: UseFormGetValues<FormDNModel>
    formStatus: string
    dnGeneralOptions?: DNGeneralOptions
    globalState: GlobalStateProps
    hasParentForm: boolean
    userInfo: UserInfo
    isMounted: MutableRefObject<boolean>
  },
  ref: ForwardedRef<HTMLUListElement>,
) {
  const contractNoList =
    globalState.contractList?.map((x) => ({
      key: x.id,
      value: x.contractNo,
    })) || []

  const readonly = formStatus !== FormStatusEnum.FORM_NF_DRAFT
  const [teamList, setTeamList] = useState(globalState.teamList)
  useEffect(() => {
    if (globalState.teamList) setTeamList(globalState.teamList)
  }, [globalState])

  const AmountOfDeductionField = () => {
    const nonComplianceId = useWatch({ control, name: 'nonComplianceId' })

    const nonCompliance = dnGeneralOptions?.typeOfNonComplianceList?.find(
      (x) => x.id === nonComplianceId,
    )

    return (
      <>
        <FormField fieldName="Amount of Deduction">
          <FormTypography type="number" value={nonCompliance?.amountOfDeduction} />
        </FormField>

        {(nonCompliance?.dnRefNo === 'DN13' || nonCompliance?.dnRefNo === 'DN15') && (
          <FormField fieldName="Refundable Amount">
            <FormTypography type="number" value={nonCompliance.refundAmount} />
          </FormField>
        )}
      </>
    )
  }

  const ClientContractorField = () => {
    const contractNoId = useWatch({
      control,
      name: 'baseForm.contractNoId',
    })
    const matchedContract = globalState?.contractList?.find(
      (contractNo) => contractNo.id === contractNoId,
    )

    return (
      <>
        <FormField fieldName="From">
          <FormTypography type="string" value={matchedContract?.client} />
        </FormField>
        <FormField fieldName="Contractor">
          <FormTypography type="string" value={matchedContract?.contractor} />
        </FormField>
      </>
    )
  }

  return (
    <FormContainer fkey={`${UUID}-dn-a`}>
      <FormField fieldName="Contract No.">
        <FormController
          controllerProps={{
            control,
            name: 'baseForm.contractNoId',
            rules: { required: true },
          }}>
          <FormSelect fullWidth options={contractNoList} uneditable={true} />
        </FormController>
      </FormField>

      <FormField fieldName="Team">
        <FormController
          controllerProps={{
            control,
            name: 'baseForm.teamId',
            rules: { required: true },
          }}>
          <FormMultipleSelect
            options={hasParentForm || readonly ? teamList ?? [] : GetTeamOptions(userInfo.teams)}
            uneditable={hasParentForm || readonly}
          />
        </FormController>
      </FormField>

      <FormField fieldName="District">
        <FormController
          controllerProps={{
            control,
            name: 'baseForm.districtId',
            rules: { required: true },
          }}>
          <FormMultipleSelect
            uneditable={hasParentForm || readonly}
            options={
              hasParentForm || readonly
                ? globalState.districtList || []
                : GetDistrictOptions(userInfo.districts)
            }
          />
        </FormController>
      </FormField>

      <ClientContractorField />

      <FormField fieldName="NF No">
        <FormController
          controllerProps={{
            control,
            name: 'parentNf.nfNo',
          }}>
          <FormTypography />
        </FormController>
      </FormField>

      <FormField fieldName="Type of Non-Compliance">
        <FormController
          controllerProps={{
            control,
            name: 'nonComplianceId',
          }}>
          <FormSelect
            fullWidth
            options={
              dnGeneralOptions?.typeOfNonComplianceList?.map((x) => ({
                key: x.id,
                value: x.dnRefNo + ' - ' + x.typeOfNonCompliance,
              })) || []
            }
            uneditable={readonly}
          />
        </FormController>
      </FormField>

      {getValues('parentDna') ? (
        <FormField fieldName="DNA No">
          <FormController controllerProps={{ control, name: 'parentDna.dnaNo' }}>
            <FormTypography />
          </FormController>
        </FormField>
      ) : (
        <FormField fieldName="DN No">
          <FormController controllerProps={{ control, name: 'dnNo' }}>
            <FormTypography />
          </FormController>
        </FormField>
      )}

      <AmountOfDeductionField />

      {(formStatus === FormStatusEnum.FORM_DN_APPEALED ||
        formStatus === FormStatusEnum.FORM_DN_ENDORSEMENT_REJECTED ||
        formStatus === FormStatusEnum.FORM_DN_APPEAL_ENDORSED ||
        formStatus === FormStatusEnum.FORM_DN_APPEAL_APPROVAL_REJECTED ||
        formStatus === FormStatusEnum.FORM_DN_ISSUED ||
        formStatus === FormStatusEnum.FORM_DN_APPEAL_SUCCESS) && (
        <FormField fieldName="Reference No.">
          <FormController
            controllerProps={{
              control,
              name: 'referenceNo',
            }}>
            <FormFreeText
              uneditable={
                formStatus === FormStatusEnum.FORM_DN_ENDORSEMENT_REJECTED ||
                formStatus === FormStatusEnum.FORM_DN_APPEAL_ENDORSED ||
                formStatus === FormStatusEnum.FORM_DN_APPEAL_APPROVAL_REJECTED ||
                formStatus === FormStatusEnum.FORM_DN_ISSUED ||
                formStatus === FormStatusEnum.FORM_DN_APPEAL_SUCCESS
              }
            />
          </FormController>
        </FormField>
      )}

      <FormField fieldName="Works Order No.">
        <FormTypography value={getValues('baseForm.workOrderNo')} />
      </FormField>

      {getValues('parentDna') ? (
        <FormField fieldName="Date & Time of Checking">
          <FormController
            controllerProps={{
              control,
              name: 'parentDna.dateOfCheck',
              rules: { required: true },
            }}>
            <FormDateTimePicker type="datetime" uneditable={true} />
          </FormController>
        </FormField>
      ) : (
        <FormField fieldName="Date & Time of Checking">
          <FormController
            controllerProps={{
              control,
              name: 'parentNf.dateOfCheck',
              rules: { required: true },
            }}>
            <FormDateTimePicker type="datetime" uneditable={true} />
          </FormController>
        </FormField>
      )}

      <FormField fieldName="Start of NC Period">
        <FormController
          controllerProps={{
            control,
            name: 'startAt',
          }}>
          <FormDateTimePicker type="datetime" uneditable />
        </FormController>
      </FormField>

      <FormField fieldName="End of NC Period">
        <FormController
          controllerProps={{
            control,
            name: 'endAt',
          }}>
          <FormDateTimePicker type="datetime" uneditable />
        </FormController>
      </FormField>

      {getValues('parentDna') ? (
        <>
          <FormField fieldName="Location">
            <FormController
              controllerProps={{
                control,
                name: 'parentDna.location',
              }}>
              <FormMediumMap uneditable isMounted={isMounted.current} ref={ref} />
            </FormController>
          </FormField>

          <FormField fieldName="Road">
            <FormController
              controllerProps={{
                control,
                name: 'parentDna.roadName',
              }}>
              <FormTypography />
            </FormController>
          </FormField>
        </>
      ) : (
        <>
          <FormField fieldName="Location">
            <FormController
              controllerProps={{
                control,
                name: 'parentNf.location',
              }}>
              <FormMediumMap uneditable isMounted={isMounted.current} ref={ref} />
            </FormController>
          </FormField>

          <FormField fieldName="Road">
            <FormController
              controllerProps={{
                control,
                name: 'parentNf.roadName',
              }}>
              <FormTypography />
            </FormController>
          </FormField>
        </>
      )}

      <FormField fieldName="SOR Item No.">
        <FormController
          controllerProps={{
            control,
            name: 'sorItem',
          }}>
          <FormFreeText
            sx={{ width: '90%' }}
            uneditable={formStatus !== FormStatusEnum.FORM_DN_DRAFT && formStatus !== FormStatusEnum.FORM_DN_ISSUE_REJECTED}
            textfieldProps={{
              fullWidth: true,
              rows: 10,
              type: 'number',
              inputProps: { min: '0' },
            }}
          />
        </FormController>
      </FormField>

      {formStatus === FormStatusEnum.FORM_DN_CANCEL && (
        <>
          <FormField fieldName="DN Cancelled by">
            <FormController
              controllerProps={{
                control,
                name: 'dnCancelledBy',
              }}>
              <FormTypography value={getValues('dnCancelledBy')} />
            </FormController>
          </FormField>

          <FormField fieldName="Date of Cancellation">
            <FormController
              controllerProps={{
                control,
                name: 'dateOfCancellation',
              }}>
              <FormTypography value={getValues('dateOfCancellation')} type='date'/>
            </FormController>
          </FormField>

          <FormField fieldName="Comment/ Reason for Delete DN">
            <FormController
              controllerProps={{
                control,
                name: 'cancelComment',
              }}>
              <FormTypography value={getValues('cancelComment')} />
            </FormController>
          </FormField>
        </>
      )}
    </FormContainer>
  )
}

export const PartA = forwardRef(PartAInner)
