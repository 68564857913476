import moment from 'moment'
import {
  filter,
  find,
  get,
  reduce,
  size,
} from 'lodash'
import { templateMapping } from '@utils/mmValue'
export const getNeaFormData = (data: any, optionList?: any) => {
  const { globalState, neaGeneralOptions, eaCategoryList = [], roadType = [] } = optionList

  const { auditingOfficer = [], checkingOfficer = [] } = neaGeneralOptions
  const mmValue = get(data, 'typeOfMmWorksValue', '')

  let templateType = ''
  Object.keys(templateMapping).forEach((type) => {
    if (templateMapping[type].includes(mmValue)) {
      templateType = type
    }
  })

  const neas = get(data, 'neaSampleResult', []).map(({ formEa = [], ...rest }) => {
    let remarks = ''
    switch (templateType) {
      case 'structuralMaintainence':
        remarks = `Road Name: 	${get(data, 'baseForm.districtId', [])
          .map((district) => {
            return get(find(globalState.districtList, { key: district }), 'value')
          })
          .join(',')} , ${get(formEa, '0.neaLocation', '')}
            6-M.I./G.I./P.I. Report No:		${get(formEa, '0.neaAuditCode', '')}
            Structure number:	${get(formEa, '0.neaStructureNo', '')}
            Plan area of the structure: ${get(
              formEa,
              '0.planAreaOfStructure',
              '',
            )} Plan Area of the structure m2
            `
        break
      case 'tunnel':
        remarks = `Tunnel Name: ${get(data, 'baseForm.districtId', [])
          .map((district) => {
            return get(find(globalState.districtList, { key: district }), 'value')
          })
          .join(',')} , ${get(formEa, '0.neaLocation', '')}
              Sample selected:		${get(formEa, '0.neaAuditCode', '')}
              Total Tunnel Portions length Sampled:	${get(formEa, '0.neaLength', '')}m2
            `
        break
      case 'detailedInspection':
        remarks = `Road Name: 	${get(data, 'baseForm.districtId', [])
          .map((district) => {
            return get(find(globalState.districtList, { key: district }), 'value')
          })
          .join(',')} , ${get(formEa, '0.neaLocation', '')}
            Sample selected: ${get(formEa, '0.neaAuditCode', '')}
            Road Type:	${get(find(roadType, { key: get(formEa, '0.neaRoadTypeId') }), 'value', '')}
            `
        break
      case 'slope':
        remarks = `Road Name: 	${get(data, 'baseForm.districtId', [])
          .map((district) => {
            return get(find(globalState.districtList, { key: district }), 'value')
          })
          .join(',')} , ${get(formEa, '0.neaLocation', '')}
            Slope No:		${get(formEa, '0.neaGeoRegistrationNo', '')}, ${get(
          formEa,
          '0.neaHydRegistrationNo',
          '',
        )}
            RMI Report:	${get(formEa, '0.neaAuditCode', '')}
            `
        break
      case 'stepping':
        remarks = `Road Name: ${get(data, 'baseForm.districtId', [])
          .map((district) => {
            return get(find(globalState.districtList, { key: district }), 'value')
          })
          .join(',')} , ${get(formEa, '0.neaLocation', '')}
            Sample selected: ${get(formEa, '0.neaAuditCode', '')}
            Total area checked:	${get(formEa, '0.neaShapeLength', '')}
            `
        break
      case 'landscape':
        remarks = `Road Name: ${get(data, 'baseForm.districtId', [])
          .map((district) => {
            return get(find(globalState.districtList, { key: district }), 'value')
          })
          .join(',')} , ${get(formEa, '0.neaLocation', '')}
            Sample selected: ${get(formEa, '0.neaAuditCode', '')}
            Slope No:	${get(formEa, '0.neaGeoRegistrationNo', '')}	Vegetation ID:		${get(
          formEa,
          '0.neaVegetationId',
          '',
        )}
            `
        break
      case 'roadMarking':
      case 'roadDrainage':
        remarks = `Road Name: ${get(data, 'baseForm.districtId', [])
          .map((district) => {
            return get(find(globalState.districtList, { key: district }), 'value')
          })
          .join(',')} , ${get(formEa, '0.neaLocation', '')}
            Sample selected: ${get(formEa, '0.neaAuditCode', '')}
            Road Type:	${get(find(roadType, { key: get(formEa, '0.neaRoadTypeId') }), 'value', '')} 
            `
        break
      case 'routineInspection':
        remarks = `Road Name: ${get(data, 'baseForm.districtId', [])
          .map((district) => {
            return get(find(globalState.districtList, { key: district }), 'value')
          })
          .join(',')} , ${get(formEa, '0.neaLocation', '')}
            Sample selected: ${get(formEa, '0.neaAuditCode', '')}
            Road Type:	${get(find(roadType, { key: get(formEa, '0.neaRoadTypeId') }), 'value', '')} 
            `
        break
    }

    return {
      ...rest,
      locationReference: get(formEa, '0.neaAuditCode', ''),
      reportNo: get(formEa, '0.reportNo', ''),
      noOfDefect: get(formEa, '0.formDeaList', []).length,
      defective: get(formEa, '0.defective', false) ? 1 : 0,
      viableToConductInspection:
        get(formEa, '0.viableToConductInspection', undefined) === undefined
          ? ''
          : get(formEa, '0.viableToConductInspection', true)
          ? 'Available'
          : 'Unavailable',
      remarks: remarks,
    }
  })

  return {
    contractTitle: get(
      find(globalState?.contractList, { id: get(data, 'baseForm.contractNoId') }),
      'contractTitle',
      '',
    ),
    contract_no: get(
      find(globalState?.contractList, { id: get(data, 'baseForm.contractNoId') }),
      'contractNo',
      '',
    ),
    typeOfMmWorks: get(
      find(eaCategoryList, { key: get(data, 'baseForm.typeOfMmWorks') }),
      'value',
      '',
    ),
    typeOfMmWorksValue: get(data, 'typeOfMmWorksValue', ''),
    neaNotificationNo: get(data, 'neaNotiticationNo', ''),
    workOrderNo: get(data, 'baseForm.workOrderNo', ''),
    monthYearOfPerformanceStandard: get(data, 'monthYearOfPerformanceStandard')
      ? moment(data?.monthYearOfPerformanceStandard).format('YYYY MMMM').toString() ?? ''
      : '',
    dateOfAudit: get(data, 'dateOfAudit')
      ? moment(get(data, 'dateOfAudit')).format('D MMMM YYYY') ?? ''
      : '',
    auditingOfficer: get(
      find(auditingOfficer, { id: get(data, 'auditingOfficer', '') }),
      'name',
      '',
    ),
    checkingOfficer: get(
      find(checkingOfficer, { id: get(data, 'checkingOfficer', '') }),
      'name',
      '',
    ),
    neaSampleResult: neas,
    noOfSelectedItem: size(filter(neas, { viableToConductInspection: 'Available' })),
    noOfDefectiveSection: reduce(
      neas,
      (result, n) => {
        return get(n, 'defective', 0) + result
      },
      0,
    ),
  }
}
