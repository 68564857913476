import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import uuid from 'react-uuid'
import {
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { CDRGeneralOptions, FormCdrModel } from '@services/model/form/form.CDR.model'
import moment from 'moment'
import FormBinaryRadio from '@components/form/binaryRadio'
import {
  useWatch,
  FieldArrayWithId,
  Control,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form'
import { KeyValPair } from '@models/common'
import { useTranslation } from 'react-i18next'
import BookIcon from '@mui/icons-material/Book'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import FormFreeText from '@components/form/freeText'
import FormTable from '@components/form/table'
import FormTypography from '@components/form/typography'
import { Fragment, useState } from 'react'
import FormRadio from '@components/form/radio'
import { FormStatusEnum } from '@services/model/form/form.model'
import { GlobalStateProps } from '@providers/globalStore'

export default ({
  control,
  getOptionsByKey,
  getValues,
  globalState,
  InitWorkActivityDetail,
  setValue,
  workActivities,
  formStatus,
  options,
}: {
  control: Control<FormCdrModel>
  getOptionsByKey: any
  getValues: any
  globalState: GlobalStateProps
  InitWorkActivityDetail: any
  setValue: UseFormSetValue<FormCdrModel>
  workActivities: any
  formStatus: string
  options: CDRGeneralOptions
}) => {
  const UUID = uuid()
  let index = 0
  const [open, setOpen] = useState<boolean[]>(workActivities?.map((_) => true) ?? [])

  const handleCollapseOpen = (index: number) => {
    const _open = [...open]
    _open[index] = !_open[index]
    setOpen(_open)
  }

  const PartBSiteIdleReasonOther = ({
    control,
    options,
    workActivityIndex,
  }: {
    control: Control<FormCdrModel>
    options: KeyValPair[]
    workActivityIndex: number
  }) => {
    const siteIdlingReason = useWatch({
      name: `workActivities.${workActivityIndex}.siteIdlingReason`,
      control,
    })

    const founded = options?.find((o) => o.value === 'Others')?.key === siteIdlingReason

    if (founded) {
      return (
        <FormField fieldName="Other">
          <FormController
            controllerProps={{
              name: `workActivities.${workActivityIndex}.otherInfo`,
              control: control,
            }}>
            <FormFreeText
              textfieldProps={{
                fullWidth: true,
                rows: 7,
              }}
              uneditable={formStatus !== FormStatusEnum.FORM_CDR_DRAFT && formStatus !== 'Draft'}
            />
          </FormController>
        </FormField>
      )
    }
    return <></>
  }

  const CDRWorkActivity = ({
    workActivity,
    workActivityIndex,
    control,
    getValues,
    setValue,
    handleCollapseOpen,
    open,
    getOptionsByKey,
  }: {
    workActivity: FieldArrayWithId<FormCdrModel, 'workActivities', 'id'>
    workActivityIndex: number
    control: Control<FormCdrModel>
    getValues: UseFormGetValues<FormCdrModel>
    setValue: UseFormSetValue<FormCdrModel>
    handleCollapseOpen: (index: number) => void
    open: boolean[]
    getOptionsByKey: (key: string) => KeyValPair[]
  }) => {
    const { t } = useTranslation()
    const [siteActive, isCompleted, reasonForNoActivity, typeOfWorks] = useWatch({
      name: [
        `workActivities.${workActivityIndex}.siteActive`,
        `workActivities.${workActivityIndex}.isCompleted`,
        `workActivities.${workActivityIndex}.reasonForNoActivity`,
        'typeOfWorks',
      ],
      control,
    })

    const notYetCommenced =
      reasonForNoActivity ===
      options?.reasonForNoActivityList?.find((x) => x.name === 'Not yet commenced')?.id
    const commencedButSiteIdle =
      reasonForNoActivity ===
      options?.reasonForNoActivityList?.find((x) => x.name === 'Commenced but site idle')?.id
    const completedOnDate =
      reasonForNoActivity ===
      options?.reasonForNoActivityList?.find((x) => x.name.includes('Completed on '))?.id
    const completedInEarlyMorning =
      reasonForNoActivity ===
      options?.reasonForNoActivityList?.find((x) =>
        x.name.includes('Completed in early morning on '),
      )?.id

    const siteStatusDate = getValues('formDate')
      ? moment(getValues('formDate')).subtract(1, 'days').format('D MMMM YYYY')
      : ''

    const completionReportDate = getValues('formDate')
      ? moment(getValues('formDate')).add(1, 'days').format('D MMMM YYYY')
      : ''

    const completionReportDateForEarlyMorning = getValues('formDate')
      ? moment(getValues('formDate')).add(2, 'days').format('D MMMM YYYY')
      : ''

    return (
      <List key={`wo_wa_${workActivityIndex}`}>
        <ListItemButton
          onClick={() => {
            handleCollapseOpen(workActivityIndex)
          }}>
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText primary={workActivityIndex + 1} />
          {open[workActivityIndex] ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open[workActivityIndex]}>
          <Fragment>
            <FormField fieldName="Works Order No." index={index++}>
              <FormController
                controllerProps={{
                  name: `workActivities.${workActivityIndex}.workOrderNo`,
                  control,
                }}>
                <FormTypography />
              </FormController>
            </FormField>
            <FormField fieldName="XP/SSW/EO No." index={index++}>
              <FormController
                controllerProps={{
                  name: `workActivities.${workActivityIndex}.emergencySerialNo`,
                  control,
                }}>
                <FormTypography />
              </FormController>
            </FormField>
            <FormField fieldName="Site Activity">
              <FormController
                controllerProps={{
                  name: `workActivities.${workActivityIndex}.siteActive`,
                  control: control,
                }}>
                <FormBinaryRadio
                  binaryOptionNames={{ yes: 'Active', no: 'Inactive' }}
                  uneditable={formStatus !== FormStatusEnum.FORM_CDR_DRAFT}
                />
              </FormController>
            </FormField>
            {siteActive === undefined ? (
              <></>
            ) : siteActive ? (
              formStatus !== FormStatusEnum.FORM_CDR_DRAFT &&
              (formStatus === FormStatusEnum.FORM_CDR_SUBMITTED ? (
                <FormField fieldName="Assigned To">
                  <FormController
                    controllerProps={{
                      name: `workActivities.${workActivityIndex}.assignTo`,
                      control: control,
                    }}>
                    <FormSelect
                      options={
                        options.assignedTo?.map((x) => {
                          return {
                            key: x.id,
                            value: x.name,
                          }
                        }) || []
                      }
                      additionalChangeAction={(_, newValue) => {
                        if (newValue !== null) {
                          setValue(
                            `workActivities.${workActivityIndex}.assignedToUserName`,
                            newValue.value,
                          )
                        }
                      }}
                    />
                  </FormController>
                </FormField>
              ) : (
                <FormField fieldName="Assigned To">
                  <FormController
                    controllerProps={{
                      name: `workActivities.${workActivityIndex}.assignedToUserName`,
                      control: control,
                    }}>
                    <FormTypography />
                  </FormController>
                </FormField>
              ))
            ) : (
              <></>
            )}
            {siteActive === undefined ? (
              <></>
            ) : siteActive ? (
              typeOfWorks ? (
                <>
                  {/* siteActive = active && typeOfWorks = M&M*/}
                  <FormTable
                    columns={[
                      {
                        field: 'roadNamenLocDetails',
                        displayName: 'Road Name & Location Details',
                        type: 'string',
                      },
                      {
                        field: 'proposedActivity',
                        displayName: 'Proposed Activity',
                        type: 'string',
                      },
                      {
                        field: 'dayNightWorks',
                        displayName: 'Day/Night Works',
                        type: 'select',
                        options:
                          options?.dayNightWorks?.map((x) => {
                            return {
                              key: x.id,
                              value: x.name,
                            }
                          }) || [],
                        // hidden: !getValues('typeOfWorks'),
                      },
                      {
                        field: 'noOfWorker',
                        displayName: 'No. of Worker',
                        type: 'number',
                      },
                      {
                        field: 'lsgOnSite',
                        displayName: 'LSG On Site',
                        type: 'binarySelect',
                      },
                    ]}
                    fieldArrayProps={{
                      name: `workActivities.${workActivityIndex}.workActivityDetails`,
                      control,
                    }}
                    uneditable={formStatus !== FormStatusEnum.FORM_CDR_DRAFT}
                    disabled={formStatus !== FormStatusEnum.FORM_CDR_DRAFT}
                  />
                </>
              ) : (
                <>
                  {/*siteActive = active && typeOfWorks = non M&M */}
                  <FormTable
                    columns={[
                      {
                        field: 'roadNamenLocDetails',
                        displayName: 'Road Name & Location Details',
                        type: 'string',
                        uneditable: formStatus !== FormStatusEnum.FORM_CDR_DRAFT,
                      },
                      {
                        field: 'proposedActivity',
                        displayName: 'Proposed Activity',
                        type: 'string',
                        uneditable: formStatus !== FormStatusEnum.FORM_CDR_DRAFT,
                      },
                      {
                        field: 'dayNightWorks',
                        displayName: 'Day/Night Works',
                        type: 'select',
                        options:
                          options?.dayNightWorks?.map((x) => {
                            return {
                              key: x.id,
                              value: x.name,
                            }
                          }) || [],
                        uneditable: formStatus !== FormStatusEnum.FORM_CDR_DRAFT,
                        // hidden: !getValues('typeOfWorks'),
                      },
                      {
                        field: 'noOfWorker',
                        displayName: 'No. of Worker',
                        type: 'number',
                        uneditable: formStatus !== FormStatusEnum.FORM_CDR_DRAFT,
                      },
                      {
                        field: 'lsgOnSite',
                        displayName: 'LSG On Site',
                        type: 'binarySelect',
                        uneditable: formStatus !== FormStatusEnum.FORM_CDR_DRAFT,
                      },
                      {
                        field: 'essentialOperation',
                        displayName: 'Essential Operation',
                        type: 'binarySelect',
                        uneditable: formStatus !== FormStatusEnum.FORM_CDR_DRAFT,
                      },
                      {
                        field: 'hiddenWorkOtherThanLsg',
                        displayName: 'Hidden Work Other Than LSG',
                        type: 'binarySelect',
                        uneditable: formStatus !== FormStatusEnum.FORM_CDR_DRAFT,
                      },
                      {
                        field: 'inSituTest',
                        displayName: 'In-situ Tests/In-situ Sampling',
                        type: 'binarySelect',
                        uneditable: formStatus !== FormStatusEnum.FORM_CDR_DRAFT,
                      },
                      {
                        field: 'remarks',
                        displayName: 'IOW Remarks',
                        type: 'string',
                        uneditable: formStatus !== FormStatusEnum.FORM_CDR_SUBMITTED,
                      },
                    ]}
                    fieldArrayProps={{
                      name: `workActivities.${workActivityIndex}.workActivityDetails`,
                      control,
                    }}
                    uneditable={formStatus === FormStatusEnum.FORM_CDR_ACKNOWLEDGED}
                    removeAddButton={formStatus !== FormStatusEnum.FORM_CDR_DRAFT}
                    removeDeleteButton={formStatus !== FormStatusEnum.FORM_CDR_DRAFT}
                  />
                  {formStatus !== FormStatusEnum.FORM_CDR_DRAFT &&
                    (formStatus === FormStatusEnum.FORM_CDR_SUBMITTED ? (
                      <FormField fieldName="Assigned To">
                        <FormController
                          controllerProps={{
                            name: `workActivities.${workActivityIndex}.assignTo`,
                            control: control,
                          }}>
                          <FormSelect
                            options={
                              options.assignedTo?.map((x) => {
                                return {
                                  key: x.id,
                                  value: x.name,
                                }
                              }) || []
                            }
                            additionalChangeAction={(_, newValue) => {
                              if (newValue !== null) {
                                setValue(
                                  `workActivities.${workActivityIndex}.assignedToUserName`,
                                  newValue.value,
                                )
                              }
                            }}
                          />
                        </FormController>
                      </FormField>
                    ) : (
                      <FormField fieldName="Assigned To">
                        <FormController
                          controllerProps={{
                            name: `workActivities.${workActivityIndex}.assignedToUserName`,
                            control: control,
                          }}>
                          <FormTypography />
                        </FormController>
                      </FormField>
                    ))}
                </>
              )
            ) : (
              <>
                {/*siteActive = inactive  */}
                <FormField fieldName="Reason for No Activity">
                  <FormController
                    controllerProps={{
                      name: `workActivities.${workActivityIndex}.reasonForNoActivity`,
                      control: control,
                    }}>
                    <FormSelect
                      fullWidth
                      options={
                        options?.reasonForNoActivityList?.map((x) => {
                          let name = x.name
                          if (name.includes('{yesterday date}')) {
                            name = name.replace('{yesterday date}', siteStatusDate)
                          } else if (name.includes('{today date}')) {
                            name = name.replace(
                              '{today date}',
                              moment(getValues('formDate')).format('D MMMM YYYY'),
                            )
                          }
                          return {
                            key: x.id,
                            value: name,
                          }
                        }) || []
                      }
                      uneditable={formStatus !== FormStatusEnum.FORM_CDR_DRAFT}
                    />
                  </FormController>
                </FormField>
                {commencedButSiteIdle ? (
                  <>
                    <FormField fieldName="Site Idle Reason">
                      <FormController
                        controllerProps={{
                          name: `workActivities.${workActivityIndex}.siteIdlingReason`,
                          control: control,
                        }}>
                        <FormRadio
                          labels={
                            options?.siteIdlingReasonList?.map((x) => {
                              return {
                                key: x.id,
                                value: x.name,
                              }
                            }) || []
                          }
                          uneditable={formStatus !== FormStatusEnum.FORM_CDR_DRAFT}
                        />
                      </FormController>
                    </FormField>
                    <PartBSiteIdleReasonOther
                      control={control}
                      options={
                        options?.siteIdlingReasonList?.map((x) => {
                          return {
                            key: x.id,
                            value: x.name,
                          }
                        }) || []
                      }
                      workActivityIndex={workActivityIndex}
                    />
                  </>
                ) : (
                  <></>
                )}
                {completedOnDate || completedInEarlyMorning ? (
                  isCompleted ? (
                    <>
                      {/* completedOnDate || completedInEarlyMorning = true && isCompleted = true */}
                      <FormField fieldName="Reported Completion Date">
                        <FormController
                          controllerProps={{
                            name: `workActivities.${workActivityIndex}.reportedCompletionDate`,
                            control,
                          }}>
                          <FormTypography type="date" />
                        </FormController>
                      </FormField>
                      <FormField fieldName="Reminder">
                        {completedOnDate ? (
                          <FormTypography
                            value={`${t(
                              'Please submit completion report by',
                            )} ${completionReportDate}`}
                          />
                        ) : (
                          <FormTypography
                            value={`${t(
                              'Please submit completion report by',
                            )} ${completionReportDateForEarlyMorning}`}
                          />
                        )}
                      </FormField>
                      <FormField fieldName="Completion Report Status">
                        <FormController
                          controllerProps={{
                            name: `workActivities.${workActivityIndex}.crStatus`,
                            control,
                          }}>
                          <FormTypography />
                        </FormController>
                      </FormField>
                    </>
                  ) : (
                    <>
                      {/* completedOnDate || completedInEarlyMorning = true && isCompleted = false */}
                      <FormField fieldName="Reminder">
                        {completedOnDate ? (
                          <FormTypography
                            value={`${t(
                              'Please submit completion report by',
                            )} ${completionReportDate}`}
                          />
                        ) : (
                          <FormTypography
                            value={`${t(
                              'Please submit completion report by',
                            )} ${completionReportDateForEarlyMorning}`}
                          />
                        )}
                      </FormField>
                    </>
                  )
                ) : (
                  <></>
                )}
                {/* {isCompleted} */}
              </>
            )}
          </Fragment>
        </Collapse>
      </List>
    )
  }

  return (
    <FormContainer fkey={`${UUID}-cdr-b`}>
      <Grid item xs={12}>
        <List>
          {workActivities?.map((workActivity, index) => {
            return (
              <CDRWorkActivity
                workActivity={workActivity}
                workActivityIndex={index}
                control={control}
                getValues={getValues}
                setValue={setValue}
                handleCollapseOpen={handleCollapseOpen}
                open={open}
                getOptionsByKey={getOptionsByKey}
              />
            )
          })}
        </List>
      </Grid>
    </FormContainer>
  )
}
