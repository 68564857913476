import moment from 'moment'
import { GetFormStatusId } from '@utils/getFormStatusId'
import {
  filter,
  find,
  findLast,
  first,
  get,
  map,
} from 'lodash'
import { FormStatusEnum } from '@services/model/form/form.model'

export const getDnFormData = (data: any, optionList?: any) => {
    const { globalState, dnGeneralOptions } = optionList
  
    let tempTotalProposedDeductionAmount = 0
    const draftStage = findLast(get(data, 'baseForm.formStatusHistories'), ({ formStatusId }) => {
      return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_DRAFT])?.includes(
        formStatusId,
      )
    })
    const issuedStage = findLast(
      get(data, 'parentForm.baseForm.formStatusHistories'),
      ({ formStatusId }) => {
        return GetFormStatusId(globalState?.formStatusList, [
          FormStatusEnum.FORM_DNA_IOW_REVIEW,
        ])?.includes(formStatusId)
      },
    )
    const reviewStage = findLast(get(data, 'baseForm.formStatusHistories'), ({ formStatusId }) => {
      return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_REVIEW])?.includes(
        formStatusId,
      )
    })
    const reviewIssuedStage = findLast(
      get(data, 'parentForm.baseForm.formStatusHistories'),
      ({ formStatusId }) => {
        return GetFormStatusId(globalState?.formStatusList, [
          FormStatusEnum.FORM_DNA_ECTO_REVIEW,
        ])?.includes(formStatusId)
      },
    )
    // Draft might be empty if there is no Draft stage, added special handling below
    const draft = draftStage ? draftStage : issuedStage
    const review = reviewStage ? reviewStage : reviewIssuedStage
    // Extract data from issued if draft stage does not exist
    const draftBy_draft = get(
      find(globalState.userMetaFullList, { userId: get(draft, 'actionBy') }),
      'userName',
      '',
    )
    const draftBy_issued = get(
      find(globalState.userMetaFullList, { userId: get(draft, 'createdBy') }),
      'userName',
      '',
    )
    const draft_post_draft = get(
      find(globalState.userMetaFullList, { userId: get(draft, 'actionBy') }),
      'position',
      '',
    )
    const draft_post_issued = get(
      find(globalState.userMetaFullList, { userId: get(draft, 'createdBy') }),
      'position',
      '',
    )
    const draft_datetime_draft = get(draft, 'actionAt')
      ? moment(get(draft, 'actionAt')).format(moment.defaultFormat).toString() ?? ''
      : ''
    const draft_datetime_issued = get(draft, 'createdAt')
      ? moment(get(draft, 'createdAt')).format(moment.defaultFormat).toString() ?? ''
      : ''
    // Ends
    const appealed = findLast(get(data, 'baseForm.formStatusHistories'), ({ formStatusId }) => {
      return GetFormStatusId(globalState?.formStatusList, [
        FormStatusEnum.FORM_DN_APPEALED,
      ])?.includes(formStatusId)
    })
    const rectitication = findLast(get(data, 'baseForm.formStatusHistories'), ({ formStatusId }) => {
      return GetFormStatusId(globalState?.formStatusList, [
        FormStatusEnum.FORM_NF_RECTIFICATION,
      ])?.includes(formStatusId)
    })
    const res = {
      checking_datetime: get(data, 'parentNf.dateOfCheck', get(data, 'parentDna.dateOfCheck', ''))
        ? moment(get(data, 'parentNf.dateOfCheck', get(data, 'parentDna.dateOfCheck', '')))
            .format(moment.defaultFormat)
            .toString() ?? ''
        : '',
      contract_no: get(
        find(globalState?.contractList, { id: get(data, 'baseForm.contractNoId') }),
        'contractNo',
        '',
      ),
      contract_title: get(
        find(globalState?.contractList, { id: get(data, 'baseForm.contractNoId') }),
        'contractTitle',
        '',
      ),
      contractor: get(
        find(globalState?.contractList, { id: get(data, 'baseForm.contractNoId') }),
        'contractor',
        '',
      ),
      deduction_amount: get(
        find(dnGeneralOptions?.typeOfNonComplianceList, { id: get(data, 'nonComplianceId', '') }),
        'amountOfDeduction',
      ),
      draftBy: draftBy_draft ? draftBy_draft : draftBy_issued,
      draft_datetime: draft_datetime_draft ? draft_datetime_draft : draft_datetime_issued,
      draft_post: draft_post_draft ? draft_post_draft : draft_post_issued,
      lastDnDraftDatetime: get(draft, 'updatedAt')
        ? moment(get(draft, 'updatedAt')).format(moment.defaultFormat).toString() ?? ''
        : '',
      NF_no: get(data, 'parentNf.nfNo', ''),
      nc_startAt: get(data, 'startAt')
        ? moment(get(appealed, 'startAt')).format(moment.defaultFormat).toString() ?? ''
        : '',
      rectification_datetime: get(rectitication, 'actionAt')
        ? moment(get(rectitication, 'actionAt')).format(moment.defaultFormat).toString() ?? ''
        : '',
      reference: '',
      review_datetime: get(review, 'actionAt')
        ? moment(get(review, 'actionAt')).format(moment.defaultFormat).toString() ?? ''
        : '',
      review_name: get(review, 'actionByUserName', ''),
      review_post: get(
        find(globalState.userMetaFullList, { userId: get(review, 'actionBy') }),
        'position',
        '',
      ),
      review_signature: get(review, 'actionSignatureBase64', ''),
      road: get(data, 'parentNf.roadName', get(data, 'parentDna.roadName', '')),
      sor_item: get(data, 'sorItem', ''),
      works_order_no: get(data, 'baseForm.workOrderNo', ''),
      formDn: filter(get(data, 'parentForm.formDn'), (dn) => {
        return map(get(dn, 'baseForm.formStatusHistories'), 'formStatusId').includes(
          first(GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_ISSUED])),
        )
      }).map((dn) => {
        const amountOfDeduction = get(
          find(dnGeneralOptions?.typeOfNonComplianceList, { id: dn.nonComplianceId }),
          'amountOfDeduction',
        )
          ? get(
              find(dnGeneralOptions?.typeOfNonComplianceList, { id: dn.nonComplianceId }),
              'amountOfDeduction',
            )
          : 0
        let status = get(dn, 'baseForm.formStatus')
        let appealStatusResult = ''
        // Checking for display contractor's appeal or not
        let appealDisplayStatus = true
        if (
          status === FormStatusEnum.FORM_DN_DRAFT ||
          status === FormStatusEnum.FORM_DN_REVIEW ||
          status === FormStatusEnum.FORM_DN_ISSUED ||
          status === FormStatusEnum.FORM_DN_ISSUE_REJECTED
        ) {
          appealDisplayStatus = false
        }
        // ends
        // Checking for display Results of appeal or not
        let approveDisplayStatus = false
        if (
          status === FormStatusEnum.FORM_DN_APPEAL_SUCCESS ||
          status === FormStatusEnum.FORM_DN_APPEAL_APPROVAL_REJECTED
        ) {
          approveDisplayStatus = true
        }
        // ends
        if (status === FormStatusEnum.FORM_DN_APPEAL_SUCCESS) {
          appealStatusResult = 'Success'
        } else if (status === FormStatusEnum.FORM_DN_APPEAL_APPROVAL_REJECTED) {
          appealStatusResult = 'Rejected'
        } else {
          appealStatusResult = '-'
        }
        const approved = findLast(get(dn, 'baseForm.formStatusHistories'), ({ formStatusId }) => {
          return GetFormStatusId(globalState?.formStatusList, [
            FormStatusEnum.FORM_DN_APPEAL_SUCCESS,
          ])?.includes(formStatusId)
        })
        const refundableAmount =
          get(dn, 'baseForm.formStatus') === FormStatusEnum.FORM_DN_APPEAL_SUCCESS
            ? get(
                find(dnGeneralOptions?.typeOfNonComplianceList, { id: dn.nonComplianceId }),
                'refundAmount',
              )
            : 0
        const typeOfNonCompliance = get(
          find(dnGeneralOptions?.typeOfNonComplianceList, { id: dn.nonComplianceId }),
          'typeOfNonCompliance',
        )
        const proposedDeductionAmount = amountOfDeduction - refundableAmount
        tempTotalProposedDeductionAmount += proposedDeductionAmount
        return {
          amountOfDeduction: amountOfDeduction ? amountOfDeduction : '-',
          appealedBy:
            get(dn, 'baseForm.createdByUserName') && appealDisplayStatus
              ? get(dn, 'baseForm.createdByUserName')
              : '-',
          appealed_datetime:
            get(dn, 'baseForm.createdAt') && appealDisplayStatus
              ? moment(get(dn, 'baseForm.createdAt')).format(moment.defaultFormat).toString()
              : '-',
          appealed_post:
            get(dn, 'baseForm.createdBy') && appealDisplayStatus
              ? get(
                  find(globalState.userMetaFullList, { userId: get(dn, 'baseForm.createdBy') }),
                  'position',
                  '',
                )
              : '-',
          appealStatus: appealStatusResult,
          approvedBy:
            get(approved, 'createdByUserName') && approveDisplayStatus
              ? get(approved, 'createdByUserName')
              : '-',
          approved_post:
            findLast(get(approved, 'createdByUserRole')) && approveDisplayStatus
              ? get(
                  find(globalState.userMetaFullList, { userId: get(approved, 'createdBy') }),
                  'position',
                  '',
                )
              : '-',
          dnNo: get(dn, 'dnNo'),
          endAt: get(dn, 'endAt')
            ? moment(get(dn, 'endAt')).format(moment.defaultFormat).toString()
            : '-',
          proposedDeductionAmount: proposedDeductionAmount ? proposedDeductionAmount : '-',
          refundableAmount: refundableAmount && approveDisplayStatus ? refundableAmount : '-',
          startAt: get(dn, 'startAt')
            ? moment(get(dn, 'startAt')).format(moment.defaultFormat).toString()
            : '-',
          typeOfNonCompliance: typeOfNonCompliance ? typeOfNonCompliance : '-',
        }
      }),
      totalProposedDeductionAmount: tempTotalProposedDeductionAmount
        ? tempTotalProposedDeductionAmount
        : 0,
    }
    return res
  }