import ConvertorsService from '@services/convertors.service'
import createReport from 'docx-templates'
import moment, { defaultFormat } from 'moment'
import { GetFormStatusId } from './getFormStatusId'
import {
  chunk,
  filter,
  find,
  findLast,
  first,
  get,
  last,
  map,
  reduce,
  size,
  upperCase,
} from 'lodash'
import { FormStatusEnum } from '@services/model/form/form.model'
import { templateMapping, templateMappingV2 } from '@utils/mmValue'
import { GetBase64FromUrl, GetImageDimensions } from './image'
import { getFormData } from './export/data'

const additionalJsContext = {
  urlToImage: async (url: string, w?: number, h?: number) => {
    try {
      let svg_data = await GetBase64FromUrl(url)
      if (svg_data !== null || svg_data !== undefined) {
        svg_data = typeof svg_data === 'string' ? svg_data?.split(',')[1] : svg_data
      }
      let widthHeight = await GetImageDimensions(url)
      let width = 15
      let height = (15 / widthHeight.width) * widthHeight.height

      if (w) {
        width = w
        height = (w / widthHeight.width) * widthHeight.height
      } else if (h) {
        height = h
        width = (h / widthHeight.height) * widthHeight.width
      }

      return {
        width: width ?? 6,
        height: height ?? 6,
        data: svg_data,
        extension: '.jpeg',
      }
    } catch (err) {
      console.error('Error in exporting image in pdf.')
    }
  },
  base64ToImage: async (base64: string, w?: number, h?: number) => {
    try {
      let widthHeight = await GetImageDimensions(base64)
      let width = 15
      let height = (15 / widthHeight.width) * widthHeight.height

      if (w) {
        width = w
        height = (w / widthHeight.width) * widthHeight.height
      } else if (h) {
        height = h
        width = (h / widthHeight.height) * widthHeight.width
      }

      return {
        width: width ?? 6,
        height: height ?? 6,
        data: base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
        extension: '.jpeg',
      }
    } catch (err) {
      console.error('Error in exporting image in pdf.', err)
    }
  },
  signature: async (signatureBase64: string) => {
    try {
      return {
        width: 3,
        height: 0.7,
        data: signatureBase64,
        extension: '.jpeg',
      }
    } catch (e: any) {
      console.error('[SignatureBase64 Error]', signatureBase64, e)
    }
  },
}

const exportEa = async (data: any, optionList?: any) => {
  try {
    const template = await getTemplate('/templates/EA_template.docx')
    const { globalState, eaCategoryList = [], roadType = [] } = optionList
    const contractNoId = get(data, 'baseForm.contractNoId')
    const mmValue = get(data, 'typeOfMmWorksValue', '')
    let templateType: string[] = []

    // If contract name is 05/HY/2018(id: 7) and 08/HT/2021(id: 8), use templateMappingV2
    const mapping = contractNoId === 7 || contractNoId === 8 ? templateMappingV2 : templateMapping
    Object.keys(mapping).forEach((type) => {
      if (mapping[type].includes(mmValue)) {
        templateType.push(type)
      }
    })

    for (let i = 0; i < templateType.length; i++) {
      const mmType = templateType[i]

      const reviewPrelimResult = findLast(
        get(data, 'baseForm.formStatusHistories'),
        ({ formStatusId }) => {
          return GetFormStatusId(globalState?.formStatusList, [
            FormStatusEnum.FORM_EA_REVIEW_PRELIM_RESULT,
          ])?.includes(formStatusId)
        },
      )
      const endorsePrelimResult = findLast(
        get(data, 'baseForm.formStatusHistories'),
        ({ formStatusId }) => {
          return GetFormStatusId(globalState?.formStatusList, [
            FormStatusEnum.FORM_EA_ENDORSE_PRELIM_RESULT,
          ])?.includes(formStatusId)
        },
      )
      const reviewAuditAppeal = findLast(
        get(data, 'baseForm.formStatusHistories'),
        ({ formStatusId }) => {
          return GetFormStatusId(globalState?.formStatusList, [
            FormStatusEnum.FORM_EA_REVIEW_AUDIT_APPEAL,
          ])?.includes(formStatusId)
        },
      )
      const endorseAuditAppeal = findLast(
        get(data, 'baseForm.formStatusHistories'),
        ({ formStatusId }) => {
          return GetFormStatusId(globalState?.formStatusList, [
            FormStatusEnum.FORM_EA_ENDORSE_AUDIT_APPEAL,
          ])?.includes(formStatusId)
        },
      )
      const auditResultIssued = findLast(
        get(data, 'baseForm.formStatusHistories'),
        ({ formStatusId }) => {
          return GetFormStatusId(globalState?.formStatusList, [
            FormStatusEnum.FORM_EA_AUDIT_RESULT_ISSUED,
          ])?.includes(formStatusId)
        },
      )

      const auditingOffier = globalState.userMetaFullList?.find(
        (x) => x.userId === get(data, 'formNea.auditingOfficer'),
      )
      const checkingOfficer = globalState.userMetaFullList?.find(
        (x) => x.userId === get(data, 'formNea.checkingOfficer'),
      )

      const res = {
        ...data,
        mmType: mmType,
        mmTypeTitle: upperCase(mmValue),
        contractor: get(
          find(globalState?.contractList, { id: get(data, 'baseForm.contractNoId') }),
          'contractor',
          '',
        ),
        contract_title: get(
          find(globalState?.contractList, { id: get(data, 'baseForm.contractNoId') }),
          'contractTitle',
          '',
        ).replace('Highways Department Term Contract', ''),
        contract_no: get(
          find(globalState?.contractList, { id: get(data, 'baseForm.contractNoId') }),
          'contractNo',
          '',
        ),
        works_order_no: get(data, 'baseForm.workOrderNo', ''),
        monthYearOfPerformanceStandard: get(data, 'formNea.monthYearOfPerformanceStandard')
          ? moment(get(data, 'formNea.monthYearOfPerformanceStandard'))
              .format('YYYY MMMM')
              .toString() ?? ''
          : '',
        auditingOfficer: `${auditingOffier?.userName ?? ''} (${auditingOffier?.position ?? ''})`,
        checkingOfficer: `${checkingOfficer?.userName ?? ''} (${checkingOfficer?.position ?? ''})`,
        actualCheckingDate: get(data, 'actualCheckingDate')
          ? moment(get(data, 'actualCheckingDate')).format(moment.defaultFormat).toString() ?? ''
          : '',
        defectiveNo: size(get(data, 'formDeaList', [])),
        defective: get(data, 'defective', false) ? 1 : 0,
        definition:
          eaCategoryList?.find((x) => x.typeOfMmWorks === get(data, 'formNea.typeOfMmWorks'))
            ?.defectDefinition ?? '',
        otherInfo: get(data, 'otherInfo', ''),
        district: get(data, 'baseForm.districtId', [])
          .map((district) => {
            return get(find(globalState.districtList, { key: district }), 'value')
          })
          .join(','),
        neaStructureNo: get(data, 'neaStructureNo', ''),
        neaShapeLength: ['detailedInspection'].includes(mmType)
          ? get(data, 'neaShapeArea', '')
          : get(data, 'neaShapeLength', ''),
        neaLocation: get(data, 'neaLocation', ''),
        neaAuditCode: get(data, 'neaAuditCode', ''),
        neaGeoRegistrationNo: get(data, 'neaGeoRegistrationNo', ''),
        neaHydRegistrationNo: get(data, 'neaHydRegistrationNo', ''),
        neaVegetationId: get(data, 'neaVegetationId', ''),
        neaSampleNo: get(data, 'neaSampleNo', ''),
        planAreaOfStructure: get(data, 'planAreaOfStructure', ''),
        roadType: get(find(roadType, { key: get(data, 'neaRoadTypeId') }), 'value'),
        viableToConductInspection: get(data, 'viableToConductInspection', '') ? 'Available' : '-',

        reviewPrelimResultBy: get(
          find(globalState.userMetaFullList, { userId: get(reviewPrelimResult, 'actionBy') }),
          'userName',
          '',
        ),
        reviewPrelimResultDatetime: get(reviewPrelimResult, 'actionAt')
          ? moment(get(reviewPrelimResult, 'actionAt')).format(moment.defaultFormat).toString() ??
            ''
          : '',
        reviewPrelimResultPost: get(
          find(globalState.userMetaFullList, { userId: get(reviewPrelimResult, 'actionBy') }),
          'position',
          '',
        ),

        endorsePrelimResultBy: get(
          find(globalState.userMetaFullList, { userId: get(endorsePrelimResult, 'actionBy') }),
          'userName',
          '',
        ),
        endorsePrelimResultDatetime: get(endorsePrelimResult, 'actionAt')
          ? moment(get(endorsePrelimResult, 'actionAt')).format(moment.defaultFormat).toString() ??
            ''
          : '',
        endorsePrelimResultPost: get(
          find(globalState.userMetaFullList, { userId: get(endorsePrelimResult, 'actionBy') }),
          'position',
          '',
        ),

        reviewAuditAppealBy: get(
          find(globalState.userMetaFullList, { userId: get(reviewAuditAppeal, 'actionBy') }),
          'userName',
          '',
        ),
        reviewAuditAppealDatetime: get(reviewAuditAppeal, 'actionAt')
          ? moment(get(reviewAuditAppeal, 'actionAt')).format(moment.defaultFormat).toString() ?? ''
          : '',

        endorseAuditAppealBy: get(
          find(globalState.userMetaFullList, { userId: get(endorseAuditAppeal, 'actionBy') }),
          'userName',
          '',
        ),
        endorseAuditAppealDatetime: get(endorseAuditAppeal, 'actionAt')
          ? moment(get(endorseAuditAppeal, 'actionAt')).format(moment.defaultFormat).toString() ??
            ''
          : '',

        auditResultIssuedBy: get(
          find(globalState.userMetaFullList, { userId: get(auditResultIssued, 'actionBy') }),
          'userName',
          '',
        ),
        auditResultIssuedDatetime: get(auditResultIssued, 'actionAt')
          ? moment(get(auditResultIssued, 'actionAt')).format(moment.defaultFormat).toString() ?? ''
          : '',
        auditResultIssuedPost: get(
          find(globalState.userMetaFullList, { userId: get(auditResultIssued, 'actionBy') }),
          'position',
          '',
        ),

        eaImages: chunk(get(data, 'eaImages', []), 2)?.map((image, index) => {
          return {
            index: index * 2 + 1,
            file: image,
          }
        }),

        formDeaList: get(data, 'formDeaList', []).map(
          ({ images = [], defectsIdentified = 0, ...res }) => {
            const defect = find(
              globalState.generalOptionGroupList
                ?.find((x) => x.name === 'Form EA - Category')
                ?.generalOptions?.find((x) => x.id === get(data, 'formNea.typeOfMmWorks'))
                ?.defectOptions,
              { id: defectsIdentified },
            )
            return {
              ...res,
              defectsIdentified: `${get(defect, 'defectCode', '')} - ${get(
                defect,
                'description',
                '',
              )}`,
              images: images === null ? [] : images,
            }
          },
        ),

        auditLocationPlanImages: get(data, 'auditLocationPlanImages', []),
        eaAppealImages: get(data, 'eaAppealImages', []),
        appealInfo: get(data, 'appealInfo', ''),
        summaryMapBase64: get(data, 'summaryMapBase64', ''),
      }

      const report = await createReport({
        // @ts-ignore
        template,
        data: res,
        additionalJsContext: additionalJsContext,
      })

      await saveDataToFile(
        report,
        `EA_${mmType}_${get(data, 'reportNo', 'EA')}.pdf`,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      )
    }
  } catch (err) {
    console.error(err)
    return err
  }
}

const exportDocument = async (
  templatePath: string,
  reportName: String,
  data: any,
  data2: any,
  optionList?: any,
) => {
  const template = await getTemplate(templatePath)
  try {
    const report = await createReport({
      // @ts-ignore
      template,
      data: getFormData(templatePath, data, data2, optionList),
      additionalJsContext: additionalJsContext,
    })

    await saveDataToFile(
      report,
      reportName + '.pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    )
  } catch (err) {
    console.error(err)
    return err
  }
}

async function getTemplate(dataPath): Promise<Blob> {
  const request = await fetch(dataPath)
  const defaultTemplate = await request.blob()
  return defaultTemplate
}

// Load the user-provided file into an ArrayBuffer
const readFileIntoArrayBuffer = (fd): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsArrayBuffer(fd)
  })

const saveDataToFile = async (data, fileName, mimeType) => {
  const blob = new Blob([data], { type: mimeType })

  const formData = new FormData()
  formData.append('file', blob)
  const pdfBlob = await ConvertorsService.ConvertFiles(formData)

  const url = window.URL.createObjectURL(pdfBlob)
  console.log(url)
  console.log(fileName)
  downloadURL(url, fileName, mimeType)
  setTimeout(() => {
    window.URL.revokeObjectURL(url)
  }, 1000)
}

const downloadURL = (data, fileName, mimeType) => {
  const a = document.createElement('a')
  a.href = data
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export { exportDocument, exportEa }
