import { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import { FormContext } from '@providers/formStateProvider'
import { NavigateTo } from '@utils/navigate'
import {
  CdrOptions,
  FormScrModel,
  InitSCRForm as InitScrForm,
  ScrGeneralOptions,
} from '@services/model/form/form.SCR.model'
import useFormSwitcher from '@hooks/useFormSwitcher'
import { DevTool } from '@hookform/devtools'
import FormService from '@services/form.service'
import useAPIFetch from '@hooks/useAPIFetch'
import FormScrService from '@services/formService/form.SCR.service'
import { useSnackbar } from 'notistack'
import SCRPartA from './part-a'
import SCRPartB from './part-b'
import SCRPartC from './part-c'
import SCRPartD from './part-d'
import SCRPartE from './part-e'
import SCRPartF from './part-f'
import SCRPartG from './part-g'
import SCRPartH from './part-h'
import SCRPartI from './part-i'
import ContractService from '@services/contract.service'
import { WorkOrderList } from '@services/model/contract.model'
import { FormStatusEnum } from '@services/model/form/form.model'
import { FormPartComponents } from '@models/common'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ValidationToast from '@components/form/ValidationToast'
import getFormValidationSchema from '@utils/formValidationSchema'
import useLargeMap from '@hooks/useLargeMap'
import { find, get } from 'lodash'
import useLocalStorage from '@hooks/useLocalStorage'
import { exportDocument } from '@utils/documentExporter'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'

const SCRForm = () => {
  const { dispatch: globalAction, userInfo, state: globalState } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)
  const { enqueueSnackbar } = useSnackbar()
  const isMounted = useRef(false)

  const { t } = useTranslation()
  const navigate = useNavigate()
  let { id: formId } = useParams<string>()
  const [storageContractId, setStorageContractId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionRequest, isLoading: isLoadingOption } = useAPIFetch()

  const scrGeneralOptions = useMemo<ScrGeneralOptions>(() => {
    return globalState.formOptionsList?.find(({ key }) => key === 'SCR')?.value ?? {}
  }, [globalState.formOptionsList])

  const [workOrderList, setWorksOrderList] = useState<WorkOrderList[]>()
  const [cdrOptions, setCdrOptions] = useState<CdrOptions | undefined>()

  const { state } = useLocation()
  const { cloneData } = state || {}

  const { getOptionsByKey } = useGeneralOptions()

  const validationSchemaConditions = [
    {
      formStatusList: [FormStatusEnum.FORM_SCR_DRAFT, FormStatusEnum.FORM_SCR_REJECTED],
      validationSchema: yup.object().shape({
        baseForm: yup.object().shape({
          contractNoId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0, t('Contract No. is required'))
            .required(),
          teamId: yup.array().nullable().min(1).required(t('Team is required')),
          districtId: yup.array().nullable().min(1).required(t('District is required')),
          workOrderId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0)
            .required(t('Works Order No. is required')),
        }),
        formDate: yup.date().required(t('Date is required')),
        cdrRef: yup.boolean().required(t("Contractor's Daily Report Ref. is required")),
        cdrId: yup.number().when('cdrRef', {
          is: true,
          then: yup.number().min(0).required(t("Contractor's Daily Report No. is required")),
          otherwise: yup.number().nullable(),
        }),
        worksStatusId: yup.number().required(t('Works Status is required')),
        itemNo: yup.number().when('cdrRef', {
          is: true,
          then: yup.number().required(t('Item No. is required')),
          otherwise: yup.number().nullable(),
        }),
        contractorResources: yup
          .array()
          .of(
            yup.object().shape({
              description: yup
                .string()
                .required(t('Description in Site Progress table is required')),
              number: yup.number().required(t('Number in Site Progress table is required')),
              resourceTypeId: yup
                .number()
                .required(t('Resource Type in Site Progress table is required')),
            }),
          )
          .nullable(),
      }),
    },
    {
      formStatusList: [
        FormStatusEnum.FORM_SCR_REVIEW,
        FormStatusEnum.FORM_SCR_APPROVED,
        FormStatusEnum.FORM_SCR_COUNTERSIGNED,
      ],
      validationSchema: yup.object(),
    },
  ]

  const { control, watch, setValue, getValues, reset, trigger } = useForm<FormScrModel>({
    defaultValues: { ...InitScrForm },
    resolver: (data, context, options) => {
      const validatorSchema = getFormValidationSchema(
        data.baseForm.formStatus,
        validationSchemaConditions,
      )
      return yupResolver(validatorSchema)(data, context, options)
    },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })

  const [formStatus, formPermission] = getValues(['baseForm.formStatus', 'baseForm.formPermission'])

  const handleGetWorksOrderNo = async () => {
    if (
      formStatus === FormStatusEnum.FORM_SCR_DRAFT ||
      formStatus === FormStatusEnum.FORM_SCR_REJECTED
    ) {
      setRequest({
        callback: async () => {
          let workOrderId = getValues('baseForm.workOrderId') || 0
          const list = await FormScrService.GetCdrOptions({
            contractNoId: getValues('baseForm.contractNoId'),
            formDate: getValues('formDate') || new Date(),
            WorkOrderId: workOrderId,
          }).catch(() => {
            enqueueSnackbar(t('Error in fetching Work order data, please try again'), {
              variant: 'error',
            })
          })
          console.log('list', list)
          if (list) {
            return setCdrOptions(list)
          }
        },
      })
    }
  }

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await FormScrService.GetScrForm(formId)
            .then(async (f) => {
              if (f) reset(f)

              if (f.baseForm.contractNoId && f.baseForm.contractNoId !== globalState.contractNoId) {
                globalAction({
                  type: 'selectContract',
                  contractNoId: f.baseForm.contractNoId,
                })

                globalAction({
                  type: 'selectWorkGroup',
                  workGroupId: get(
                    find(globalState.contractList, { id: f.baseForm.contractNoId }),
                    'workGroups.0.id',
                    0,
                  ),
                })
              }

              globalAction({
                type: 'changeFormStatus',
                formStatus: f.baseForm.formStatusName,
              })
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
              NavigateTo(navigate, '/site-check-record/all-record')
            })
        }

        isMounted.current = true
      },
    })
  }

  const reloadOthers = async () => {
    await ContractService.GetWorkOrderListForSCR()
      .then((options) => {
        setWorksOrderList(options)
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: null })
      })
  }

  const cloneDataToNewScr = () => {
    reset(cloneData)
    setValue('baseForm.formStatus', FormStatusEnum.FORM_SCR_DRAFT)
    setValue('baseForm.formStatusName', 'Draft')
    setValue('baseForm.formStatusHistories', InitScrForm.baseForm.formStatusHistories)
    globalAction({
      type: 'changeFormStatus',
      formStatus: 'Draft',
    })
  }

  useEffect(() => {
    if (!isMounted.current) {
      if (formState.form && formState.formType === 'SCR') {
        formStateAction({ type: 'pop', targetFormType: 'SCR' })

        reset({ ...formState.form })
      } else if (formId) {
        reload()
      } else {
        if (cloneData) {
          cloneDataToNewScr()
        } else {
          setValue('baseForm.contractNoId', storageContractId ? parseInt(storageContractId) : 0)
          setValue('baseForm.teamId', userInfo.teams)
          setValue('baseForm.districtId', userInfo.districts)
        }
      }

      reloadOthers()

      globalAction({
        type: 'changeFormStatus',
        formStatus: getValues('baseForm.formStatusName'),
      })

      isMounted.current = true
    }

    return () => {
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      }) // nav bar remove status
    }
  }, [formId, formStateAction, globalAction])

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    if (!(await trigger())) return
    setValue('signatureBase64', signatureBase64)
    var ff = getValues()
    ff.signatureBase64 = signatureBase64
    ff.notifyUserList = notifyList
    ff.submissionComment = submissionComment

    if (isReject) {
      setRequest({
        callback: async () => {
          await FormScrService.RejectScrForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/site-check-record/all-record')
          })
        },
      })
    } else {
      setRequest({
        callback: async () => {
          console.log('form', ff)
          await FormScrService.ApproveScrForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/site-check-record/all-record')
          })
        },
      })
    }
  }

  const handleOnSave = async () => {
    setRequest({
      callback: async () => {
        await FormScrService.SaveScrForm(getValues()).then((resp) => {
          setValue('baseForm.formId', resp.formId)
          setValue('baseForm.formStatus', resp.formStatus)
          NavigateTo(navigate, '/site-check-record/:id', {
            id: resp.formId,
          })
          formId = resp.formId
          reload()
          enqueueSnackbar(t('Record Saved'), { variant: 'success' })
        })
      },
    })
  }

  const handleOnDelete = async () => {
    setRequest({
      callback: async () => {
        await FormScrService.DeleteScrForm(getValues())
          .then(() => {
            enqueueSnackbar(t('Record Archived'), { variant: 'success' })
            NavigateTo(navigate, '/site-check-record/all-record')
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, { variant: 'error' })
          })
      },
    })
  }

  const onLoadNotifyList = async (isReject: boolean) => {
    var defaultNotifyList = await FormService.GetNotifyList(
      'SCR',
      getValues('baseForm.formId'),
      isReject,
      getValues('baseForm.districtId'),
      getValues('baseForm.teamId'),
    )
    return defaultNotifyList
  }

  const onLoadHistoryList = async () => {
    var defaultNotifyList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultNotifyList
  }

  const handleLinkClick = (index: number) => {
    formStateAction({ type: 'push', form: getValues(), formType: 'SCR' })
    NavigateTo(navigate, `/site-check-record/:id/photo`, { id: formId }, { num: index })
  }

  const handleAddPhotoClick = (_: any) => {
    formStateAction({ type: 'push', form: getValues(), formType: 'SCR' })
    NavigateTo(navigate, `/site-check-record/:id/photo`, { id: formId })
  }

  const handleExportDocument = async () => {
    setRequest({
      callback: async () => {
        await exportDocument(
          '/templates/SCR_template.docx',
          getValues('scrNo') ?? 'SCR',
          getValues(),
          {},
          {
            scrGeneralOptions: scrGeneralOptions,
            globalState: globalState,
            resourceType: getOptionsByKey('Form SD/SCR - Labour'),
            constructionPlant: getOptionsByKey('Form SD/SCR - Construction Plant'),
            wipType: getOptionsByKey('Form SD/SCR - WIP Type Sub Option'),
            workSCompletionList: getOptionsByKey('Form SD - Estimated Percentage of Works Completion'),
            essentialOperationList: getOptionsByKey('Form SD - Essential Operations Involved'),
            inSituTestList: getOptionsByKey('Form SD - In-situ Test'),
            inSituSampleList: getOptionsByKey('Form SD - In-situ Sample'),
            sitePhotoCategory: getOptionsByKey('Form SD - Site Photo Category'),
            dayNightWorks: getOptionsByKey('Form SD - Day Night Works'),
            worksStatus: getOptionsByKey('Form SD - Works Status'),
            yesNo: getOptionsByKey('Default Option - Yes/No'),
            satisfactory: getOptionsByKey('Default Option - Satisfactory/Unsatisfactory')
          }
        )
      },
    })
  }

  const [disableNav, setDisableNav] = useState<boolean>(true) //for part A onNext disable
  const partA: FormPartComponents = {
    partTitle: t('General Information'),
    component: (
      <SCRPartA
        control={control}
        useWatch={useWatch}
        workOrderList={workOrderList}
        setValue={setValue}
        getValues={getValues}
        userInfo={userInfo}
        scrGeneralOptions={scrGeneralOptions}
        handleGetWorksOrderNo={handleGetWorksOrderNo}
        cdrOptions={cdrOptions}
        watch={watch}
        formStatus={formStatus}
        setDisableNav={setDisableNav}
        globalState={globalState}
      />
    ),
    onNextDisabled: disableNav,
  }

  const partB: FormPartComponents = {
    partTitle: t("Site Progress - Contractor's Reported Works"),
    component: (
      <SCRPartB
        control={control}
        useWatch={useWatch}
        setValue={setValue}
        getValues={getValues}
        scrGeneralOptions={scrGeneralOptions}
        formStatus={formStatus}
        globalState={globalState}
      />
    ),
  }

  const partC: FormPartComponents = {
    partTitle: t('Site Progress - Progress of Works'),
    component: (
      <SCRPartC
        control={control}
        formStatus={formStatus}
        setValue={setValue}
        setDisableNav={setDisableNav}
      />
    ),
    onNextDisabled: disableNav,
  }

  const partD: FormPartComponents = {
    partTitle: t("Site Progress - Contractor's Resource on Site"),
    component: (
      <SCRPartD
        control={control}
        useWatch={useWatch}
        setValue={setValue}
        getValues={getValues}
        formStatus={formStatus}
        options={scrGeneralOptions}
      />
    ),
  }

  const [LargeMap, exportCanvas] = useLargeMap({
    value: getValues('scrPhotos'),
    uneditable: !(
      formStatus === FormStatusEnum.FORM_SCR_DRAFT ||
      formStatus === FormStatusEnum.FORM_SCR_REJECTED
    ),
  })

  const saveTargetMap = async () => {
    let targetBase64 = await exportCanvas()
    setValue('summaryMapBase64', targetBase64)
  }

  const partE: FormPartComponents = {
    partTitle: t('Site Photos'),
    component: (
      <SCRPartE
        control={control}
        handleLinkClick={handleLinkClick}
        handleAddPhotoClick={handleAddPhotoClick}
        formStatus={formStatus}
        LargeMap={LargeMap}
      />
    ),
    onNext: () => saveTargetMap(),
  }

  const partF: FormPartComponents = {
    partTitle: t('Checklist'),
    component: <SCRPartF control={control} useWatch={useWatch} formStatus={formStatus} />,
  }

  const partG: FormPartComponents = {
    partTitle: t('Temporary Hidden Works Record'),
    component: (
      <SCRPartG
        control={control}
        getValues={getValues}
        useWatch={useWatch}
        formStatus={formStatus}
      />
    ),
  }

  const partH: FormPartComponents = {
    partTitle: t('Other Information'),
    component: <SCRPartH control={control} formStatus={formStatus} />,
  }

  const partI: FormPartComponents = {
    partTitle: t('Submission Confirmation'),
    component: (
      <SCRPartI
        control={control}
        getValues={getValues}
        handleOnComplete={() => handleOnComplete()}
      />
    ),
  }

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
    title: t('SITE CHECK RECORD'),
    components: [partA, partB, partC, partD, partE, partF, partG, partH, partI],
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    onExportDocument: handleExportDocument,
    startStep: step,
    isLoading: isLoading || isLoadingOption,
    disableSave: !formPermission?.canUpdate,
    disableDelete: !formPermission?.canDelete,
    disableComment: getValues('baseForm.formId') ? false : true,
    approveRequired: formPermission?.workflowRequired,
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
  })

  return (
    <Fragment>
      <FormSwitcher />
      <ValidationToast control={control} />
      <DevTool control={control} />
    </Fragment>
  )
}

export default SCRForm
