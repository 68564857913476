import { WorkActivityDetail } from '../contract.model'
import {
	BaseFormStatusDtoForList,
	FormModel,
	FormStatusEnum,
	InitFormModel,
	InitFormStatusHistory
}                             from './form.model'
import { BaseListResponse }   from '../base.model'
import { FileModel }          from '../file.model'

export type FormCdrModel = {
	baseForm: FormModel

	//a
	cdrNo?: string
	formDate: Date
	shiftType?: number
	typeOfWorks?: boolean
	weatherId?: number

	// reportNo?: string
	// contractNoId?: string
	// teamId?: number
	// districtId?: number

	//b
	workActivities?: WorkActivity[]

	signatureRequired?: boolean
	signatureBase64?: string
	notifyUserList?: string[]
	submissionComment?: string
	skipCheckActivitiesDuplicated?: boolean

	firstPageData?: boolean
	excelRef?: boolean
	cdrExcelFile?: FileModel[]
	worksOrderReference?: string[]
}

export type WorkActivity = {
	id: number
	workOrderId: number
	workOrderNo: string
	emergencySerialNo: string
	siteActive?: boolean
	reasonForNoActivity?: number
	siteIdlingReason?: number
	otherInfo?: string
	assignTo?: string
	assignedToUserName?: string

	reportedCompletionDate?: Date
	isCompleted?: boolean
	crStatus?: string
	status: boolean

	workActivityDetails?: WorkActivityDetail[]
}

export interface CdrListResponse extends BaseListResponse {
	list: FormCdrListModel[]
}

export const InitCDRForm: FormCdrModel = {
	signatureRequired: false,
	formDate         : new Date(),
	workActivities   : [],
	cdrNo            : 'N/A',
	baseForm         : {
		...InitFormModel,
		formStatus         : FormStatusEnum.FORM_CDR_DRAFT,
		formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }]
	}
}

export const InitWorkActivity: WorkActivity = {
	id                 : 0,
	status             : true,
	workOrderId        : 0,
	workOrderNo        : '',
	emergencySerialNo  : '',
	workActivityDetails: [],
	siteActive         : undefined,
	reasonForNoActivity: undefined,
	siteIdlingReason   : undefined,
	otherInfo          : undefined
}

export interface FormCdrListModel extends BaseFormStatusDtoForList {
	formId: string
	reportNo: string
	contractNoId: number
	teamId: number[]
	districtId: number[]
	typeOfWorks: boolean
	updatedAt: Date
	formDate: Date
}

export interface CDRGeneralOptions {
	shiftType?: {
		id: number
		name: string
	}[]
	weatherList: {
		id: number
		name: string
	}[]
	dayNightWorks: {
		id: number
		name: string
	}[]
	reasonForNoActivityList: {
		id: number
		name: string
	}[]
	siteIdlingReasonList: {
		id: number
		name: string
	}[]
	assignedTo: {
		id: number
		name: string
	}[]
}
